<template>
  <div class="about">
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
    >
      <v-container>
        <h1>Barrierefreiheit</h1>
        <VueShowdown
          v-if="barrierefreiheit.accessibilityText"
          :markdown="barrierefreiheit.accessibilityText"
          flavor="github"
        ></VueShowdown>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { VueShowdown } from "vue-showdown";

export default {
  components: {
    VueShowdown
  },
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    barrierefreiheit: []
  }),
  apollo: {
    barrierefreiheit: gql`
      query barrierefreiheit {
        barrierefreiheit {
          accessibilityText
        }
      }
    `
  }
};
</script>
