<template>
  <v-img
    v-if="imageObject"
    contain
    width="100%"
    height="100%"
    class="action-image"
    :srcset="srcset"
    :sizes="sizes"
    :src="imageObject.url"
    :alt="imageObject.alternativeText"
  ></v-img>
</template>

<script>
export default {
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    imageObject: Object,
    sizes: String
  },
  computed: {
    srcset: function() {
      let srcsetString = "";
      const formats = this.imageObject.formats;
      for (var format in formats) {
        const formatString =
          formats[format].url + " " + formats[format].width + "w" + ", ";
        srcsetString = srcsetString + formatString;
      }
      return srcsetString;
    }
  }
};
</script>

<style scoped lang="css"></style>
