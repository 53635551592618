<template>
  <div class="testimonial-container">
    <div class="zitat-container rounded elevation-2">
      <p class="zitat">»{{ testimonial.Zitat }}«</p>
      <p class="h-text autor">– {{ testimonial.Autor }}</p>
      <p class="personalInfo">{{ testimonial.autorInfo }}</p>
      <p
        class="credit"
        v-if="testimonial.BildAutor && testimonial.BildAutor.caption"
      >
        {{ "Foto:" + " &copy; " + testimonial.BildAutor.caption }}
      </p>
    </div>
    <v-img
      height="80"
      class="author-image rounded elevation-2"
      width="80"
      :src="testimonial.BildAutor.formats.thumbnail.url"
      :alt="testimonial.BildAutor.alternativeText"
    ></v-img>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    testimonial: Object
  },
  methods: {}
};
</script>

<style scoped lang="css">
.testimonial-container {
  position: relative;
  display: inline-block;
  width: calc(100% - 30px);
  min-height: 130px;
}

.author-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  widows: 80px;
}

.zitat-container {
  position: relative;
  margin: 30px 0 0 30px;
  min-height: 100px;
  width: 100%;
  padding: 10px 60px;
  background-color: white;
  font-size: 1.3em;
}

.zitat {
  font-style: italic;
}

.autor {
  text-align: right;
  color: var(--v-primary-base);
  /* margin-bottom: 1; */
}

.personalInfo {
  text-align: right;
  font-weight: light;
  font-size: 16px;
  margin-bottom: 0;
  /* line-height: 2; */
}
.credit {
  text-align: right;
  font-weight: light;
  font-size: 16px;
  /* line-height: 1; */
}

@media all and (max-width: 980px) {
  .zitat-container {
    padding: 60px 10px;
    margin: 30px 0 0 30px;
  }

  .testimonial-container {
    margin: 15px 0;
  }
}
</style>
