<template>
  <div class="splash">
    <hooper
      class="hooper-carousel"
      v-if="items"
      @slide="changeCurrent"
      :infiniteScroll="true"
      :mouseDrag="false"
      :touchDrag="false"
      :wheelControl="false"
      :autoPlay="true"
      :playSpeed="5000"
      :transition="10"
      :hoverPause="false"
      :vertical="true"
      >/logo.svg
      <slide
        v-for="(item, i) in itemsArray"
        :key="i"
        class="slide"
        :style="{
          backgroundImage:
            'url(\'' + item.carouselImage.formats.xlarge.url + '\')'
        }"
      >
      </slide>
    </hooper>

    <p class="statement h-text">
      <VueShowdown
        v-if="items"
        :markdown="this.items.introStatement"
        flavor="github"
      ></VueShowdown>
    </p>
    <p
      class="fact"
      v-if="items"
      :style="{
        top: this.currentItem.xPos + '%',
        left: this.currentItem.yPos + '%'
      }"
    >
      {{ currentItem.fact }}
    </p>
    <p class="credit" id="splash-credit" v-if="currentItem.carouselImage">
      {{ "&copy; " + currentItem.carouselImage.caption }}
    </p>

    <a @click="scrollToElement"
      ><img
        src="/scroll-arrow-down.svg"
        alt="scroll-arrow-down"
        class="scroll-arrow-down"
      />
    </a>
  </div>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    VueShowdown,
    Hooper,
    Slide
  },
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      currentItem: {},
      currentIndex: {}
    };
  },
  computed: {
    currentFact() {
      return this.itemsArray[this.currentIndex].fact;
    },
    currentFactXY() {
      let factXY = [
        this.itemsArray[this.currentIndex].xPos,
        this.itemsArray[this.currentIndex].yPos
      ];
      return factXY;
    },
    itemsArray() {
      return this.items.carouselItem;
    }
  },
  methods: {
    changeCurrent(currentSlide) {
      let currentIndex = currentSlide.currentSlide;
      this.currentIndex = currentIndex;
      if (currentIndex === this.itemsArray.length) {
        this.currentItem = this.itemsArray[0];
      } else {
        this.currentItem = this.itemsArray[currentIndex];
      }
    },
    scrollToElement() {
      const el = document.getElementById("skip-splash");

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        // el.scrollIntoView({ block: "start", behavior: "smooth" });
        el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        // window.scrollBy(0, -40);
      }
    }
  },
  props: {
    items: Object
  }
};
</script>

<style scoped lang="css">
.splash {
  margin-top: -80px;
  height: calc(100vh);
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  z-index: 0;
}

.hooper-carousel {
  height: 100%;
  width: calc(100% + 24px);
  pointer-events: none;
}

/* Negativer Margin wird gesetzt, weil ul padding von Vuetify nicht überschreiben werden kann  */
.hooper-carousel:first-child {
  margin-left: -24px;
}

.hooper-track {
  padding: 0 !important;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
}

/* Transition override */
/* .hooper-track {
  transition: none !important;
}

.hooper-slide,
.hooper-slide.is-prev,
.hooper-slide.is-next {
  transition: all 1s ease-in-out;
  opacity: 0 !important;
}

.hooper-slide.is-active {
  opacity: 1 !important;
} */
/* Transition override end */

#arrow-down {
  position: absolute;
  bottom: 20px;
  left: 49%;
  z-index: 2;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.splash p {
  position: absolute;
  z-index: 1;
  text-align: left;
  line-height: 1.2;
  color: var(--v-secondary-base);
}

.statement {
  top: 70px;
  left: 20px;
  font-size: 3em;
  font-weight: bold;
  line-height: 1.1 !important;
}

.fact {
  font-size: 1.3em;
  max-width: 300px;
  font-weight: regular;
  z-index: 100;
}

.scroll-arrow-down {
  position: absolute;
  left: calc(50% - 40px);
  bottom: 20px;
  z-index: 100;
  width: 80px;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: scroll;
  cursor: pointer;
}

#splash-credit {
  text-align: right;
  width: 99%;
  margin-right: 10px;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

/* Einheitliches Platzieren des Facts bei kleineren Geräten */
@media all and (max-width: 980px) {
  .fact {
    margin-left: 0px !important;
    top: 280px !important;
    left: 1em !important;
  }
}

@media all and (max-width: 370px) {
  .statement {
    font-size: 2em !important;
  }
}
</style>
