<template>
  <div>
    <div id="mapContainer" class="basemap"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
// import PopupContent from "@/components/PopupContent.vue";

export default {
  props: {
    examples: Array
  },
  name: "BaseMap",
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoicHVsc3dlcmsiLCJhIjoiY2tscWU3dHNoMWM0YzJvbjFyazFlN2Y3MyJ9.qNUzpwsa-PR6b9bbFgjmZQ"
    };
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken;

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/pulswerk/cklqe9n7f0ehp17pcufywur3a",
      center: [13.5855, 47.69],
      zoom: 6.2,
      showZoom: true,
      showCompass: true,
      scrollZoom: false,
      attributionControl: true
    });

    var mq = window.matchMedia("(min-width: 700px)");

    if (mq.matches) {
      map.setZoom(6.2); //set map zoom level for desktop size
    } else {
      map.setZoom(5); //set map zoom level for mobile size
    }

    map.on("load", () => {
      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());

      const examples = this.examples;
      for (let index = 0; index < examples.length; index++) {
        // create a HTML element for each feature
        const el = document.createElement("div");

        const popup = new mapboxgl.Popup({
          offset: [0, -42],
          closeButton: false,
          closeOnClick: false
        })
          .setLngLat({ lng: examples[index].lon, lat: examples[index].lat })
          .setHTML("<h2>" + examples[index].name + "</h2>")
          .addTo(map);

        el.className = "marker";
        // make a marker for each feature and add to the map
        const marker = new mapboxgl.Marker(el)
          .setLngLat([examples[index].lon, examples[index].lat])
          .setPopup(popup)
          .addTo(map);

        // new PopupContent({
        //   propsData: { feature: examples[index] }
        // }).$mount("#vue-popup-content");

        el.style.cursor = "pointer";

        el.addEventListener("mouseenter", () => marker.togglePopup());
        el.addEventListener("mouseleave", () => marker.togglePopup());

        el.addEventListener("click", () => {
          marker.togglePopup();
          this.$emit("set-sheet", examples[index]);
        });
      }
    });
  }
};
</script>

<style lang="css">
.basemap :focus {
  outline: none;
}

.basemap {
  width: 100%;
  height: 100%;
}

.marker {
  background-image: url("/marker.svg");
  background-size: cover;
  width: 30px;
  height: 40px;
  cursor: pointer;
  margin-top: -20px;
}

.marker:focus {
  width: 39px;
  height: 52px;
  margin-top: -26px;
}

.mapboxgl-popup-close-button {
  margin: 2px;
}
</style>
