import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Beispiele from "@/views/Beispiele.vue";
import Veranstaltungen from "@/views/Veranstaltungen.vue";
import Kontakt from "@/views/Kontakt.vue";
import Hilfreiches from "@/views/Hilfreiches.vue";
import Privacy from "@/views/Privacy.vue";
import Imprint from "@/views/Imprint.vue";
import accessibility from "@/views/accessibility.vue";
import PageNotFound from "@/views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Home" }
  },
  {
    path: "/beispiele",
    name: "beispiele",
    component: Beispiele,
    meta: { title: "Beispiele" }
  },
  {
    path: "/hilfreiches",
    name: "hilfreiches",
    component: Hilfreiches,
    meta: { title: "Hilfreiches" }
  },
  {
    path: "/veranstaltungen",
    name: "veranstaltungen",
    component: Veranstaltungen,
    meta: { title: "Veranstaltungen" }
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: Kontakt,
    meta: { title: "Kontakt" }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: { title: "Privacy" }
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Imprint,
    meta: { title: "Imprint" }
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: accessibility,
    meta: { title: "accessibility" }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { title: "Page not Found" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " – Agenda 2030 für Städte und Gemeinden";
  next();
});

export default router;
