<template>
  <v-main id="skip-here" class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0">
    <v-container>
      <h1>Umsetzungen in Österreich</h1>
      <v-row>
        <v-col>
          <Map
            :examples="beispiele.umsetzung"
            @set-sheet="showSheet"
            v-if="beispiele.umsetzung"
            id="map"
          />
        </v-col>
      </v-row>

      <masonry
        :cols="{ default: 4, 1900: 3, 1263: 2, 700: 1 }"
        :gutter="{ default: '20px', 700: '15px' }"
      >
        <div v-for="example in beispiele.umsetzung" :key="example.name">
          <Card :example="example" @set-sheet="showSheet" />
        </div>
      </masonry>

      <v-bottom-sheet scrollable v-model="sheet" width="80%" height="80vh">
        <v-sheet scrollable>
          <v-card>
            <v-btn class="mt-6 mx-6 float-right" icon @click="sheet = !sheet">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title
              ><h2 class="px-2  h-text">{{ title }}</h2></v-card-title
            >
            <v-card-text class="py-3 px-6" style="height: 70vh"
              ><VueShowdown
                :markdown="description"
                flavor="github"
              ></VueShowdown
            ></v-card-text>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </v-main>
</template>

<script>
import Card from "@/components/Card.vue";
import Map from "@/components/Map.vue";
import gql from "graphql-tag";
import { VueShowdown } from "vue-showdown";

export default {
  components: {
    Card,
    Map,
    VueShowdown
  },
  data: () => ({
    beispiele: [],
    sheet: false,
    title: "",
    description: ""
  }),
  apollo: {
    beispiele: gql`
      query umsetzungen {
        beispiele {
          umsetzung {
            name
            shortDescr
            descr
            image {
              formats
              url
              alternativeText
              caption
            }
            lat
            lon
          }
        }
      }
    `
  },
  methods: {
    showSheet: function(example) {
      this.sheet = !this.sheet;
      this.title = example.name;
      this.description = example.descr;
    }
  }
};
</script>

<style scoped lang="css">
.v-card__text {
  overflow: auto;
}
</style>
