<template>
  <div>
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
      v-if="info"
    >
      <v-row
        v-for="section in info.body"
        :key="section.id"
        class="sec d-flex justify-center"
        no-gutters
      >
        <v-col v-if="section.__typename === 'ComponentPageCallForAction'">
          <Callforaction
            :actionstatement="section.actionStatement"
            :actionimage="section.actionImage"
            :button="section.Button"
          ></Callforaction>
        </v-col>

        <v-col v-if="section.__typename === 'ComponentPageTextBlock'">
          <TextBlock
            :title="section.Title"
            :content="section.Content"
            :textBlockImage="section.textBlockImage"
            :vimeoLink="section.vimeoLink"
          ></TextBlock>
        </v-col>

        <v-row
          v-if="section.__typename === 'ComponentPageTextBoxen'"
          cols="12"
          md="6"
          no-gutters
          justify="center"
        >
          <v-col
            class="text-boxen-container d-flex flex-column align-start justify-center ma-7"
            align-content="end"
            cols="12"
            md="4"
            xl="3"
            v-for="textblock in section.smTextBlock"
            :key="textblock.id"
          >
            <h2>{{ textblock.titel }}</h2>
            <VueShowdown
              :markdown="textblock.text"
              flavor="github"
            ></VueShowdown>
          </v-col>
        </v-row>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Callforaction from "@/components/Callforaction.vue";
import TextBlock from "@/components/TextBlock.vue";

import { VueShowdown } from "vue-showdown";

export default {
  components: {
    Callforaction,
    TextBlock,

    VueShowdown
  },
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    info: []
  }),
  apollo: {
    info: gql`
      query info {
        info {
          body {
            __typename
            ... on ComponentPageCallForAction {
              actionStatement
              actionImage {
                formats
                url
                alternativeText
                caption
              }
              Button {
                title
                linkTarget
                downloadFile {
                  url
                }
              }
            }
            ... on ComponentPageTextBlock {
              Title
              Content
              vimeoLink
              textBlockImage {
                formats
                url
                alternativeText
                caption
              }
            }
            ... on ComponentPageTextBoxen {
              smTextBlock {
                titel
                text
              }
            }
          }
        }
      }
    `
  }
};
</script>

<style scoped>
#spash {
  height: 100vh;
}

.statement {
  font-size: 2em;
  font-weight: bold;
}

.sec:nth-child(odd) {
  background-color: var(--v-accent-lighten1);
}

.sec:nth-child(even) {
  background-color: var(--v-accent-lighten2);
}

.sec {
  min-height: 30vh;
  padding: 50px;
  width: 100%;
  /* background-color: var(--v-accent-lighten1); */
}

/* .dummy {
  position: relative;
  margin: 20px;
} */

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

@media all and (max-width: 980px) {
  .sec {
    padding: 15px;
  }

  .text-boxen-container {
    padding: 10px 10%;
  }
}
</style>
