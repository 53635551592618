<template>
  <div>
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
      v-if="home"
    >
      <SplashCarousel id="splash" :items="home.SplashCarousel"></SplashCarousel>
      <v-row
        id="skip-splash"
        v-for="section in home.body"
        no-gutters
        :key="section.id"
        class="sec d-flex justify-center sections"
      >
        <v-col v-if="section.__typename === 'ComponentPageTestimonial'">
          <TestimonialCarousel
            :items="section.Testimonial"
          ></TestimonialCarousel>
        </v-col>

        <v-col v-if="section.__typename === 'ComponentPageCallForAction'">
          <Callforaction
            :actionstatement="section.actionStatement"
            :actionimage="section.actionImage"
            :button="section.Button"
          ></Callforaction>
        </v-col>

        <v-col v-if="section.__typename === 'ComponentPageTextBlock'">
          <TextBlock
            :title="section.Title"
            :content="section.Content"
            :textBlockImage="section.textBlockImage"
          ></TextBlock>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Callforaction from "@/components/Callforaction.vue";
import SplashCarousel from "@/components/SplashCarousel.vue";
import TestimonialCarousel from "@/components/TestimonialCarousel.vue";
import TextBlock from "@/components/TextBlock.vue";

export default {
  components: {
    TestimonialCarousel,
    Callforaction,
    SplashCarousel,
    TextBlock
  },

  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    home: []
  }),
  apollo: {
    home: gql`
      query landing {
        home {
          SplashCarousel {
            introStatement
            carouselItem {
              id
              fact
              xPos
              yPos
              carouselImage {
                url
                formats
                alternativeText
                caption
              }
            }
          }
          body {
            __typename
            ... on ComponentPageTestimonial {
              Testimonial {
                Zitat
                Autor
                autorInfo
                BildAutor {
                  url
                  formats
                  alternativeText
                  caption
                }
              }
            }
            ... on ComponentPageCallForAction {
              actionStatement
              actionImage {
                url
                formats
                alternativeText
                caption
              }
              Button {
                title
                linkTarget
                downloadFile {
                  url
                }
              }
            }
            ... on ComponentPageTextBlock {
              Title
              Content
              textBlockImage {
                formats
                url
                alternativeText
                caption
              }
            }
          }
        }
      }
    `
  }
};
</script>

<style scoped>
.sec:nth-child(odd) {
  background-color: var(--v-accent-lighten1);
}

.sec:nth-child(even) {
  background-color: var(--v-accent-lighten2);
}

.sec {
  min-height: 30vh;
  padding: 50px;
  width: 100%;
}

@media all and (max-width: 980px) {
  .sec {
    padding: 15px;
  }
}
</style>
