<template>
  <div class="about">
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
    >
      <v-container>
        <h1>404: Page not Found!</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  created: function() {
    // Redirect outside the app using plain old javascript
    // window.location.href = "/my-new-404-page.html";
  }
};
</script>
