<template>
  <div class="about">
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0"
      v-if="aktuelle"
    >
      <v-container>
        <h1>Bevorstehende Veranstaltungen</h1>
        <v-row class="marginbottom" align="center">
          <div
            class="fullwidth"
            v-for="event in aktuelle.event"
            :key="event.id"
          >
            <v-col v-if="!isPast(event.date)" cols="12" sm="8" xl="5">
              <Event :event="event"></Event>
            </v-col>
          </div>
        </v-row>

        <h1>Vergangene Veranstaltungen</h1>
        <v-row align="center">
          <div
            class="fullwidth"
            v-for="event in aktuelle.event"
            :key="event.id"
          >
            <v-col v-if="isPast(event.date)" cols="12" sm="8" xl="5">
              <Event :event="event"></Event>
            </v-col>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Event from "@/components/Event.vue";
import moment from "moment";

// import { VueShowdown } from "vue-showdown";

export default {
  components: {
    Event
    // VueShowdown
  },
  methods: {
    isPast: function(eventDate) {
      let past = false;
      const yesterday = moment().add(-1, "days");
      if (moment(eventDate).isBefore(yesterday)) {
        past = true;
      }
      return past;
    }
  },
  computed: {},
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    aktuelle: []
  }),
  apollo: {
    aktuelle: gql`
      query event {
        aktuelle {
          event {
            id
            name
            date
            dateUntil
            timeFrom
            timeUntil
            place
            organizer
            description
            eventImage {
              formats
              url
              alternativeText
              caption
            }
            button {
              title
              linkTarget
              downloadFile {
                url
              }
            }
          }
        }
      }
    `
  }
};
</script>

<style scoped lang="css">
.fullwidth {
  width: 100%;
}

.marginbottom {
  margin-bottom: 3em;
}
</style>
