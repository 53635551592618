<template>
  <v-row class="callforaction-container">
    <v-col
      cols="12"
      md="6"
      class="text-button-container d-flex flex-column align-start justify-center"
    >
      <p class="action-statement">{{ actionstatement }}</p>
      <div v-if="actionimage">
        <div v-for="b in button" :key="b.id"><Button :button="b"></Button></div>
      </div>
    </v-col>
    <v-col cols="12" md="6" class="image-container pa-30">
      <ResponsiveImage
        :imageObject="actionimage"
        sizes="(max-width: 960px) 100vw, (min-width: 960px) 50vw"
      ></ResponsiveImage>

      <p class="credit" v-if="actionimage && actionimage.caption">
        {{ "&copy; " + actionimage.caption }}
      </p>
      <div v-if="!actionimage" class="centered-button">
        <div v-for="b in button" :key="b.id">
          <Button :button="b"></Button>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Button from "@/components/Button.vue";
import ResponsiveImage from "@/components/ResponsiveImage.vue";

export default {
  components: {
    Button,
    ResponsiveImage
  },
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    actionstatement: String,
    actionimage: Object,
    button: Array
  },
  methods: {}
};
</script>

<style scoped lang="css">
.credit {
  text-align: right;
}

.text-button-container {
  padding: 10px 10%;
}

.button-container {
  margin-bottom: 10px;
}

.image-container {
  position: relative;
  max-height: 40vh;
  min-height: 40vh;
  padding: 10px 5%;
}

.action-statement {
  font-size: 1.3em;
  font-weight: regular;
  line-height: 1.2;
}

.centered-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
