<template>
  <v-card class="mx-auto my-5">
    <ResponsiveImage
      :imageObject="example.image"
      sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw, (min-width: 960px) 33vw, (min-width: 1904ox) 25vw"
    ></ResponsiveImage>
    <p class="credit">
      {{ "&copy; " + example.image.caption }}
    </p>

    <v-card-subtitle class="h-text">
      <h2>{{ example.name }}</h2></v-card-subtitle
    >

    <v-card-text>
      {{ example.shortDescr }}
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="primary"
        class="h-text"
        text
        rounded
        @click="setSheet(example)"
      >
        mehr lesen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ResponsiveImage from "@/components/ResponsiveImage.vue";

export default {
  components: { ResponsiveImage },
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    example: Object
  },
  methods: {
    setSheet: function(example) {
      this.$emit("set-sheet", example);
    }
  }
};
</script>

<style scoped lang="css">
.card-h2 {
  font-size: 1.5em;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.credit {
  text-align: right;
  margin-right: 10px;
  margin-bottom: 0;
}
</style>
