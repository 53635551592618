<template>
  <v-card class="mx-auto my-3">
    <!-- <v-img height="250" :src="api_url + example.image.url"></v-img> -->
    <ResponsiveImage
      :imageObject="event.eventImage"
      sizes="(max-width: 960px) 100vw, (min-width: 960px) 66vw, (min-width: 1904ox) 41vw"
    ></ResponsiveImage>
    <p class="credit" v-if="event.eventImage">
      {{ "&copy; " + event.eventImage.caption }}
    </p>

    <v-card-subtitle class="h-text">
      <h2>{{ event.name }}</h2></v-card-subtitle
    >

    <v-card-text>
      <p class="header-text" v-if="event.place">
        <v-icon>mdi-map-marker-outline</v-icon>{{ event.place }}
      </p>
      <p class="header-text" v-if="event.date">
        <v-icon>mdi-calendar-range</v-icon>{{ DayMonthYear }}
      </p>
      <p class="header-text" v-if="event.timeFrom">
        <v-icon>mdi-clock-outline</v-icon>{{ Time }}
      </p>
    </v-card-text>
    <v-card-text>
      <VueShowdown>{{ event.description }}</VueShowdown>
    </v-card-text>

    <v-card-actions v-if="event.button">
      <div v-for="b in event.button" :key="b.id">
        <Button :button="b"></Button>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { VueShowdown } from "vue-showdown";
import Button from "@/components/Button.vue";
import ResponsiveImage from "@/components/ResponsiveImage.vue";

export default {
  components: {
    VueShowdown,
    Button,
    ResponsiveImage
  },
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    event: Object
  },
  computed: {
    DayMonthYear: function() {
      moment.locale("de");
      let datestring = "";
      if (this.event.date) {
        datestring = moment(this.event.date).format(" Do MMMM YYYY");
      }
      if (this.event.dateUntil) {
        datestring +=
          " -" + moment(this.event.dateUntil).format(" Do MMMM YYYY");
      }
      return datestring;
    },
    Time: function() {
      moment.locale("de");
      let timeString = "";
      if (this.event.timeFrom) {
        timeString = moment(this.event.timeFrom, "HH:mm:ss.SSS").format(
          " HH:mm"
        );
      }
      if (this.event.timeUntil) {
        timeString +=
          " -" + moment(this.event.timeUntil, "HH:mm:ss.SSS").format(" HH:mm");
      }

      return timeString;
    }
  },

  methods: {}
};
</script>

<style scoped lang="css">
.header-text {
  margin-bottom: 0.1em;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.credit {
  text-align: right;
  margin-right: 10px;
}
</style>
