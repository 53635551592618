<template>
  <v-main
    id="skip-here"
    class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0"
    v-if="kontakt"
  >
    <v-container>
      <h1>Kontakt</h1>

      <v-row>
        <v-col cols="12" md="6">
          <VueShowdown
            v-if="kontakt.kontaktText"
            :markdown="kontakt.kontaktText"
            flavor="github"
          ></VueShowdown>
          <div v-for="b in kontakt.Button" :key="b.id">
            <Button :button="b"></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import gql from "graphql-tag";
import { VueShowdown } from "vue-showdown";
import Button from "@/components/Button.vue";

export default {
  components: {
    VueShowdown,
    Button
  },
  data: () => ({
    kontakt: {}
  }),
  apollo: {
    kontakt: gql`
      query kontakt {
        kontakt {
          kontaktText
          Button {
            title
            linkTarget
            downloadFile {
              url
            }
          }
        }
      }
    `
  },
  methods: {
    showSheet: function(example) {
      this.sheet = !this.sheet;
      this.title = example.name;
      this.description = example.descr;
    }
  }
};
</script>

<style scoped lang="css">
.v-card__text {
  overflow: auto;
}
</style>
