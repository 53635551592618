import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#C15401",
        secondary: "#2c3e50",
        accent: "#ddd9d4",
        error: "#dc4643",
        anchor: "#C84209"
      }
    }
  }
});

export default vuetify;
