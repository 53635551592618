<template>
  <div class="button-container">
    <!-- Abdeckung für zwei verschiedene Button Typen: intern & extern -->
    <v-btn
      v-if="button.linkTarget && isExternal(button.linkTarget)"
      color="primary"
      rounded
      class="h-text"
      :href="button.linkTarget"
      target="_blank"
    >
      {{ button.title }} <v-icon small right> mdi-open-in-new</v-icon>
    </v-btn>
    <v-btn
      v-if="button.linkTarget && !isExternal(button.linkTarget)"
      color="primary"
      rounded
      class="h-text"
      :to="button.linkTarget"
    >
      {{ button.title }}
    </v-btn>
    <v-btn
      v-if="!button.linkTarget && button.downloadFile"
      color="primary"
      rounded
      class="h-text"
      :href="button.downloadFile.url"
      target="_blank"
    >
      {{ button.title }}
      <v-icon small right> mdi-file-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  props: {
    button: Object
  },

  methods: {
    isExternal: function(target) {
      let ext = false;
      if (target.includes(".")) {
        ext = true;
      }
      return ext;
    }
  }
};
</script>

<style scoped lang="css">
.button-container {
  margin-right: 10px;
}
</style>
