<template>
  <div class="cont">
    <hooper
      :settings="hooperSettings"
      id="testimonal-hooper"
      ref="carousel"
      @slide="updateCarousel"
    >
      <slide v-for="testimonial in items" :key="testimonial.id" class="slide">
        <Testimonial class="content" :testimonial="testimonial"></Testimonial>
      </slide>
      <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
    </hooper>
    <v-btn
      class=" arrow a-right primary"
      icon
      @click.prevent="slideNext"
      aria-label="next slide"
    >
      <v-icon color="white">mdi-arrow-right</v-icon>
    </v-btn>

    <v-btn
      class=" arrow a-left primary"
      icon
      @click.prevent="slidePrev"
      aria-label="previous slide"
    >
      <v-icon color="white">mdi-arrow-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Testimonial from "@/components/Testimonial.vue";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Testimonial,
    Hooper,
    Slide
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    }
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    }
  },

  data: function() {
    return {
      hooperSettings: {
        wheelControl: false,
        trimWhiteSpace: true,
        breakpoints: {
          800: {
            centerMode: false,
            itemsToShow: 1
          },
          1000: {
            itemsToShow: 2,
            pagination: "fraction"
          }
        }
      }
    };
  },

  props: {
    items: Array
  }
};
</script>

<style scoped lang="css">
#testimonal-hooper {
  height: auto;
  width: calc(100% + 30px);
  margin: -15px;
}

.cont {
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translate(-50%, 0%);
}

.a-right {
  right: -25px;
}

.a-left {
  left: 10px;
}

.slide {
  padding: 10px 80px 10px 0;
}
</style>
